/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any */
import type { CompositeState, PopoverState } from "ariakit";
import {
  cloneElement,
  ComponentPropsWithRef,
  createContext,
  ElementType,
  forwardRef,
  memo,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from "react";

import type { AnyAttributes } from "./html";

export * from "./__deprecated/classnames";

const PREFIX = "atlas-";
const ELEMENT_SEPARATOR = "__";

type EmptyProps = unknown;

/** Creates a props type that extends an HTML element or a different
 * props type. */
export type Props<O, P = EmptyProps> = P &
  (O extends keyof JSX.IntrinsicElements
    ? Omit<ComponentPropsWithRef<O>, keyof P>
    : Omit<O, keyof P>);

/** Creates a component type that takes a set of props.
 *
 * Doesn't work with polymorphic components (components that take the `as`
 * prop), those need to be typed manually instead due to the generic. */
export type Component<P = EmptyProps> = {
  (props: P): ReactElement | null;
  displayName?: string;
};

/** Metadata that can be attached to the component. */
export type ComponentMetadata = { id?: string; collectionType?: string };

/** Options that can be passed to `createComponent`. */
export type CreateComponentOptions = {
  /** The display name to set in the component. */
  displayName?: string;
  /**
   * If `true`, the ref will be forwarded with `React.forwardRef()`.
   * @default true
   */
  forwardRef?: boolean;
  /**
   * If `true`, the component will be memoized with `React.memo()`.
   * @default true
   */
  memo?: boolean;

  /** Metadata that will be attached to the component. */
  metadata?: ComponentMetadata;
};

/** Creates a memoized component that automatically forwards the ref and casts
 * it to the specified component type. The render callback receives all props,
 * including the ref. */
export function createComponent<
  C extends ElementType,
  P = ComponentPropsWithRef<C>
>(render: (props: P) => ReactElement | null, opt: CreateComponentOptions = {}) {
  const forwardedRefComponent =
    opt.forwardRef ?? true
      ? forwardRef((props: P, ref: React.Ref<unknown>) =>
          render({ ref, ...props })
        )
      : (render as Component);
  const component =
    opt.memo ?? true ? memo(forwardedRefComponent) : forwardedRefComponent;
  if (opt.displayName) component.displayName = opt.displayName;
  if (opt.metadata)
    (component as { _metadata?: ComponentMetadata })._metadata = opt.metadata;
  return component as unknown as C;
}

/**
 * Creates utilities for an Atlas component.
 *
 * - `ROOT` is the root element CSS class.
 * - `el` is a utility that creates a CSS class for an element. It can be used as
 * a template literal (e.g. ``el`element-name` ``) or as a regular function
 * (e.g. `el("element-name")`).
 * - `createComponent` is a wrapper over `createComponent` that takes an additional
 * option (`treeName`) that automatically sets the component's `displayName` with a
 * "tree name" format. For example, if the component name is `Table` and `Row` is
 * passed to `treeName`, the component will have `Table.Row` as its display name.
 *
 * @example
 * ```jsx
 * const { ROOT, el, createComponent } = createComponentUtils("Button");
 *
 * const Button = createComponent<ButtonProps>((props) => (
 *   <button className={ROOT} {...props}>
 *     <span className={el`label`}>Click me</span>
 *   </button>
 * );
 * ```
 */
export function createComponentUtils(componentName: string) {
  /** @deprecated Use the `treeName` option in `createComponent`. */
  function setTreeDisplayName(component: any, name: string) {
    // eslint-disable-next-line no-param-reassign
    component.displayName = `${componentName}.${name}`;
  }

  const ROOT = `${PREFIX}${componentName}`;

  function el(name: string): string;
  function el(strings: TemplateStringsArray): string;
  function el(input: string | TemplateStringsArray): string {
    const name = typeof input === "string" ? input : input[0];
    return `${ROOT}${ELEMENT_SEPARATOR}${name}`;
  }

  function createComponentFn<
    C extends ElementType,
    P = ComponentPropsWithRef<C>
  >(
    render: (props: P) => ReactElement | null,
    { treeName, ...opt }: CreateComponentOptions & { treeName?: string } = {}
  ) {
    return createComponent<C, P>(render, {
      ...opt,
      displayName: treeName ? `${componentName}.${treeName}` : opt.displayName,
    });
  }

  return { ROOT, el, createComponent: createComponentFn, setTreeDisplayName };
}

/** Read the metadata of a component. */
export function getComponentMetadata(component: ElementType) {
  return (component as { _metadata?: ComponentMetadata })._metadata ?? {};
}

/** Creates the root context for a tree component. */
export function createRootContext<T>(componentName: string) {
  const RootContext = createContext<T | undefined>(undefined);

  const { Provider: RootProvider } = RootContext;

  function useRootContext() {
    const context = useContext(RootContext);
    if (!context)
      throw new Error(
        `Can't obtain context value in ${componentName}, did you forget to add <${componentName}.Root>?`
      );
    return context;
  }

  return { RootContext, RootProvider, useRootContext };
}

type NegativeMarginPropValue = boolean | "left" | "right";

/** Props type that contains the `negativeMargin` prop. */
export type NegativeMarginProps = {
  /** Adds a negative margin to the element. Omit the value to add the margin
   * to both sides, or `left`/`right` to add it only to one side. */
  negativeMargin?: NegativeMarginPropValue;
};

/** Creates a CSS class depending on the value of the negativeMargin prop. */
export function getNegativeMarginClassName(
  negativeMargin?: NegativeMarginPropValue
) {
  switch (negativeMargin) {
    case true:
      return "hasNegativeMargin";
    case "left":
      return "hasNegativeMarginLeft";
    case "right":
      return "hasNegativeMarginRight";
    default:
      return undefined;
  }
}

type WrapperAttributes<N extends string> = { "data-atlas-wrapper": N };

type RenderFunctionProps<N extends string> = AnyAttributes &
  WrapperAttributes<N>;

type RenderFunction<N extends string, Args extends unknown[] = unknown[]> = (
  /** HTML props that must be passed down. */
  props: RenderFunctionProps<N>,
  ...args: Args
) => ReactElement | null;

/** Render props for a component that can receive them. If a component should only receive
 * some of these, `Pick<>` can be used to select the appropriate props. The `Args` type
 * argument can be used to type additional arguments that will be passed to the `render`
 * function when executed. */
export type RenderProps<
  N extends string,
  Args extends unknown[] = unknown[]
> = {
  /** Optional render function to replace the component UI. Receives the original HTML props
   * that must be passed down. */
  render?: RenderFunction<N, Args>;

  /** Optional render function to wrap the component UI in a different element, e.g. a link.
   * Receives the original HTML props that must be passed down. */
  renderWrapper?: RenderFunction<N>;
};

type CreateChildRenderFunctionArgs<
  N extends string,
  Args extends unknown[],
  P extends RenderProps<N, Args>
> = {
  props: P & { children?: ReactNode };
  name: N;
  originalElement: ReactElement;
  args?: Args;
};

/** Creates a render function for a component that can receive render props. */
export function createChildRenderFunction<
  N extends string,
  Args extends unknown[],
  P extends RenderProps<N, Args>
>({
  props: { render, renderWrapper, children },
  name,
  originalElement,
  args = [] as unknown as Args,
}: CreateChildRenderFunctionArgs<N, Args, P>) {
  if (render && renderWrapper)
    throw new Error(
      "The render and renderWrapper props are mutually exclusive"
    );

  const wrapperAttributes = { "data-atlas-wrapper": name };

  function childRenderFunction(htmlProps: AnyAttributes) {
    const renderFunctionProps = {
      ...htmlProps,
      ...wrapperAttributes,
    } as RenderFunctionProps<N>;

    if (render) return render({ ...renderFunctionProps, children }, ...args);

    if (renderWrapper)
      return renderWrapper({
        ...renderFunctionProps,
        children: originalElement,
      });

    return cloneElement(originalElement, {
      ...htmlProps,
      ...originalElement.props,
    });
  }

  return childRenderFunction;
}

/** Prop to accept a state. */
export type StateProp<S extends object> = {
  /** Replaces the state. */
  state?: S;
};

/** Props to extend an Atlas state. */
export type ExtendedStateProps<S extends object> = StateProp<S> & {
  /** Overrides properties in the state. */
  stateOverrides?: Partial<S>;
};

/** Extends a state. */
export function useExtendedState<S>({
  originalState,
  stateOverrides,
  state,
}: {
  /** The state to be extended. */
  originalState: S;
  /** Overrides for the state properties. */
  stateOverrides?: Partial<S>;
  /** If passed, it completely replaces the original state. */
  state?: S;
}): S {
  return useMemo(() => {
    const resolvedState = state ?? originalState;
    return stateOverrides
      ? { ...resolvedState, ...stateOverrides }
      : resolvedState;
  }, [state, stateOverrides, originalState]);
}

/** Extends a state.
 *
 * Automatically extracts and uses the relevant props. Returns a tuple:
 *
 * - First value: the extended state.
 * - Second value: the rest of the props. */
export function usePExtendedState<
  S extends object,
  P extends ExtendedStateProps<S>
>(
  /** The props to extract from. */
  props: P,
  /** The state to extend. */
  originalState: S
) {
  const { state, stateOverrides, ...restProps } = props;

  return [
    useExtendedState({ originalState, stateOverrides, state }),
    restProps,
  ] as const;
}

/** Overrides properties in a state. Especially useful for overriding the `@default`
 * JSDoc directive when extending an Ariakit state. */
export type StateWithOverrides<S extends object, O extends object> = Omit<
  S,
  keyof O
> &
  O;

/** Overrides state props. Especially useful for overriding the `@default`
 * JSDoc directive when extending an Ariakit state. */
export type StatePropsWithOverrides<P extends object, O extends object> = Omit<
  P,
  keyof O
> &
  Partial<O>;

/** A set of popover state defaults. */
export const SHARED_POPOVER_STATE_DEFAULTS = {
  placement: "bottom-start",
  animated: true,
  gutter: 8,
  overflowPadding: 16,
} as const;

/** A set of common popover state overrides used to update the `@default` JSDoc
 * directive to Atlas values. */
export type SharedPopoverStateOverrides = {
  /**
   * The placement of the popover.
   * @default "bottom-start"
   */
  placement: PopoverState["placement"];

  /**
   * Determines whether the content should animate when it is shown or hidden.
   *   - If `true`, the `animating` state will be `true` when the content is
   *     shown or hidden and it will wait for `stopAnimation` to be called or a
   *     CSS animation/transition to end before becoming `false`.
   *   - If it's set to a number, the `animating` state will be `true` when the
   *     content is shown or hidden and it will wait for the number of
   *     milliseconds to pass before becoming `false`.
   * @default true
   */
  animated: PopoverState["animated"];

  /**
   * The distance between the popover and the anchor element. By default, it's 0
   * plus half of the arrow offset, if it exists.
   * @default 8
   */
  gutter?: PopoverState["gutter"];

  /**
   * The minimum padding between the popover and the viewport edge. This will be
   * exposed to CSS as `--popover-overflow-padding`.
   * @default 16
   */
  overflowPadding: PopoverState["overflowPadding"];
};

/** A set of composite state defaults. */
export const SHARED_COMPOSITE_STATE_DEFAULTS = {
  focusLoop: true,
} as const;

/** A set of common composite state overrides used to update the `@default` JSDoc
 * directive to Atlas values. */
export type SharedCompositeStateOverrides = {
  /**
   * On one-dimensional composites:
   *   - `true` loops from the last item to the first item and vice-versa.
   *   - `horizontal` loops only if `orientation` is `horizontal` or not set.
   *   - `vertical` loops only if `orientation` is `vertical` or not set.
   *   - If `activeId` is initially set to `null`, the composite element will be
   *     focused in between the last and first items.
   *
   * On two-dimensional composites:
   *   - `true` loops from the last row/column item to the first item in the
   *     same row/column and vice-versa. If it's the last item in the last row,
   *     it moves to the first item in the first row and vice-versa.
   *   - `horizontal` loops only from the last row item to the first item in the
   *     same row.
   *   - `vertical` loops only from the last column item to the first item in
   *     the column row.
   *   - If `activeId` is initially set to `null`, vertical loop will have no
   *     effect as moving down from the last row or up from the first row will
   *     focus the composite element.
   *   - If `focusWrap` matches the value of `focusLoop`, it'll wrap between the
   *     last item in the last row or column and the first item in the first row
   *     or column and vice-versa.
   * @default true
   */
  focusLoop: CompositeState["focusLoop"];
};
