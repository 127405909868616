import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";

/** Factory that creates an "option extractor", which extracts a set of properties
 * from an object (typically props) and returns them in a new object. The rest
 * of the properties are returned in an object too. */
export function createOptionsExtractor<O, K extends keyof O = keyof O>(
  keysToExtract: readonly K[]
) {
  function optionExtractor<P extends O>(props: P) {
    type ExtractedO = Pick<O, K>;
    type RestP = Omit<P, K>;

    // @ts-expect-error - We will populate this object properly later on.
    const options: ExtractedO = {};
    // @ts-expect-error - We will populate this object properly later on.
    const restProps: RestP = {};

    function isKeyToExtract(key: keyof P): key is keyof ExtractedO {
      return keysToExtract.includes(key as K);
    }

    (Object.keys(props) as (keyof P)[]).forEach((key) => {
      if (isKeyToExtract(key)) {
        // skip undefined values
        if (props[key] === undefined) return;
        options[key] = props[key];
      } else restProps[key as keyof RestP] = props[key as keyof RestP];
    });
    return [options, restProps] as const;
  }

  return optionExtractor;
}

// TODO: change to a similar API to useVerticalScrollOverflow, use
// mutation observer to react to children changes, use useResizeObserver
// for consistency, and move to ../utils/layout.ts
/** Returns whether an element is currently overflowing. */
export function useIsOverflowing(
  ref: RefObject<HTMLElement>,
  children?: ReactNode
): boolean {
  const isOverflowingRef = useRef(false); // helps prevent useless re-renders
  const [isOverflowing, setIsOverflowing] = useState(false);

  const onResize = useCallback(() => {
    if (!ref.current) {
      // eslint-disable-next-line no-console
      console.warn("Empty ref passed to 'useIsOverflowing'");
      return;
    }
    const newValue =
      ref.current.offsetWidth < ref.current.scrollWidth ||
      ref.current.offsetHeight < ref.current.scrollHeight;
    if (newValue !== isOverflowingRef.current) {
      setIsOverflowing(newValue);
      isOverflowingRef.current = newValue;
    }
  }, [ref]);

  // update on resize
  useResizeDetector({ targetRef: ref, onResize });

  // TODO: would this be more efficient/correct with a mutation observer?
  // and also on children change
  useEffect(() => {
    onResize();
  }, [children, onResize]);

  return isOverflowing;
}

/** Create a random id. The id will be 5 characters long. */
export function createId() {
  return Math.random().toString(36).substring(2, 7);
}
