/* eslint-disable import/prefer-default-export */
import clsx from "clsx";
import { CSSProperties, useMemo } from "react";

import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import type {
  AtlasIconComponent,
  AtlasIconData,
  AtlasIconFragmentData,
  AtlasIconProps,
} from "./types";

// config
// ------

const COMPONENT_NAME = "Icon";

const DEFAULT_PROPS = createDefaultProps<AtlasIconProps>()({
  size: "default",
} as const);

const VIEWBOX_DEFAULT_SIZE = 20;

const VIEWBOX_METADATA_ESCAPE_SEQUENCE = "123.45";

const { ROOT, createComponent } = createComponentUtils(COMPONENT_NAME);

// icon
// ----

function extractPathViewBoxSize(path: string) {
  const regexpContent = `M${VIEWBOX_METADATA_ESCAPE_SEQUENCE.replace(
    ".",
    "\\."
  )} ([0-9]+)$`;
  const regexp = new RegExp(regexpContent);
  const result = path.match(regexp);
  return result ? +result[1] : undefined;
}

function extractFragmentViewBoxSize(fragment: AtlasIconFragmentData) {
  return fragment?.size;
}

function useViewBoxSize(data: AtlasIconData) {
  return useMemo(() => {
    if (typeof data === "string") return extractPathViewBoxSize(data);
    return extractFragmentViewBoxSize(data);
  }, [data]);
}

/**
 * An SVG icon.
 *
 * The default size will be the original icon size, which is embedded in the icon data
 * and is also used as the viewbox size, unless a different size is specified.
 *
 * If the icon data doesn't contain size information, then the default size will be 20px.
 *
 * @example
 * <Icon content="atlasCheck" />
 */
export const Icon = createComponent<AtlasIconComponent>(
  ({ size = DEFAULT_PROPS.size, content, ...props }) => {
    const viewBoxSize = useViewBoxSize(content);
    return (
      <svg
        style={
          viewBoxSize
            ? ({
                "--atlas-icon-size": `${viewBoxSize / 16}rem`,
              } as CSSProperties)
            : undefined
        }
        viewBox={`0 0 ${viewBoxSize ?? VIEWBOX_DEFAULT_SIZE} ${
          viewBoxSize ?? VIEWBOX_DEFAULT_SIZE
        }`}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={clsx(`${ROOT} size-${size}`, props.className)}
      >
        {typeof content === "string" ? (
          // SVG path
          <path d={content} fillRule="evenodd" clipRule="evenodd" />
        ) : (
          // react node
          content.data
        )}
      </svg>
    );
  }
);
