/* eslint-disable import/prefer-default-export */
import clsx from "clsx";
import { ReactElement } from "react";

import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import type {
  AtlasLoadingIndicatorComponent,
  AtlasLoadingIndicatorProps,
} from "./types";

// config
// ------

const COMPONENT_NAME = "LoadingIndicator";

const DEFAULT_PROPS = createDefaultProps<AtlasLoadingIndicatorProps>()({
  size: "medium",
  variant: "dark",
  progress: 0,
} as const);

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// loading indicator
// -----------------

/**
 * A loading indicator.
 *
 * @example
 * <LoadingIndicator isDeterminate progress={50} />
 */
export const LoadingIndicator = createComponent<AtlasLoadingIndicatorComponent>(
  ({
    size = DEFAULT_PROPS.size,
    variant = DEFAULT_PROPS.variant,
    progress = DEFAULT_PROPS.progress,
    isDeterminate,
    ...props
  }): ReactElement => (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx(
        `${ROOT} size-${size} variant-${variant}`,
        { isDeterminate, isIndeterminate: !isDeterminate },
        props.className
      )}
    >
      <circle
        className={el`path`}
        cx="50%"
        cy="50%"
        r="40%"
        fill="none"
        strokeWidth="10%"
        strokeDashoffset={isDeterminate ? 100 - progress / 2 : undefined}
      />
    </svg>
  )
);
