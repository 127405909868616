/* eslint-disable import/prefer-default-export */
import { HTMLAttributes, useEffect, useMemo, useRef } from "react";
import useStateRef from "react-usestateref";

// use keyboard active
// -------------------

type UseKeyboardActiveOptions = {
  as?: string;
  disabled?: boolean;
  "aria-disabled"?: boolean | "true" | "false";
};

/**
 * Hook that tracks whether the spacebar is currently being pressed for a button,
 * which should trigger `:active` styles. Only works for non-disabled elements that
 * are not links.
 *
 * @example
 * ```jsx
 * const { isKeyboardActive, keyboardActiveProps } = useKeyboardActive(props);
 * <button
 *   {...keyboardActiveProps}
 *   data-keyboard-active={isKeyboardActive ? "" : undefined}
 * />
 * ```
 */

export function useKeyboardActive({
  as,
  disabled,
  "aria-disabled": ariaDisabled,
}: UseKeyboardActiveOptions) {
  const [isKeyboardActive, setKeyboardActive, isKeyboardActiveRef] =
    useStateRef(false);
  const globalKeyUpListener = useRef<((event: KeyboardEvent) => void) | null>(
    null
  );

  function cleanUpGlobalListener() {
    if (globalKeyUpListener.current) {
      document.removeEventListener("keyup", globalKeyUpListener.current);
      globalKeyUpListener.current = null;
    }
  }

  // make sure we clean the global listener on unmount
  useEffect(() => cleanUpGlobalListener, []);

  return useMemo(() => {
    // bail if disabled or not a button
    if (disabled || ariaDisabled || as === "a")
      return { isKeyboardActive: false, keyboardActiveProps: {} };

    function stop() {
      // set to false if already started
      if (isKeyboardActiveRef.current) setKeyboardActive(false);
      // clean up global handler either way
      cleanUpGlobalListener();
    }

    function start() {
      // bail if already started
      if (isKeyboardActiveRef.current) return;

      // set to true
      setKeyboardActive(true);

      // register global keyup event handler, in case focus moves to a
      // different element before the "keyup" event is fired
      if (!globalKeyUpListener.current) {
        // if it doesn't already exist, create handler and register it
        globalKeyUpListener.current = (globalEvent) => {
          // stop if key is spacebar
          if (["Spacebar", " "].includes(globalEvent.key)) stop();
        };
        document.addEventListener("keyup", globalKeyUpListener.current);
      }
    }

    const keyboardActiveProps: HTMLAttributes<HTMLElement> & {
      "data-keyboard-active": "" | undefined;
    } = {
      onKeyDown: (event) => {
        // start if key is spacebar and event is not repeating
        if (["Spacebar", " "].includes(event.key) && !event.repeat) start();
      },
      onBlur: stop,
      "data-keyboard-active": isKeyboardActive ? "" : undefined,
    };

    return { isKeyboardActive, keyboardActiveProps };
  }, [
    ariaDisabled,
    as,
    disabled,
    isKeyboardActive,
    isKeyboardActiveRef,
    setKeyboardActive,
  ]);
}
