import type { ReactElement } from "react";

// config
// ------

const CONFIG_PROP = "__tooltipConfig";

// types
// -----

type TooltipConfig = {
  mode?: "slot" | "wrapper";
  isFocusDisabled?: boolean;
};

type PossiblyDecoratedComponent = ReactElement["type"] & {
  [CONFIG_PROP]?: TooltipConfig;
};

type PossiblyDecoratedElement = ReactElement & {
  type: PossiblyDecoratedComponent;
};

// utils
// -----

/** Sets a component's tooltip config.
 * @deprecated Tooltip is getting rebuilt soon and this won't be necessary anymore.
 */
export function setTooltipConfig(
  component: unknown,
  config: TooltipConfig
): void {
  // eslint-disable-next-line no-param-reassign
  (component as PossiblyDecoratedComponent)[CONFIG_PROP] = config;
}

/** Gets the tooltip config from an element.
 * @deprecated Tooltip is getting rebuilt soon and this won't be necessary anymore.
 */
export function getTooltipConfig(element: ReactElement): TooltipConfig | null {
  const tooltipConfig = (element as PossiblyDecoratedElement).type[CONFIG_PROP];
  if (!tooltipConfig) return null;
  return tooltipConfig;
}
