import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { useIsomorphicLayoutEffect } from "react-use";
import useStateRef from "react-usestateref";

// config
// ------

const INITIAL_VISIBLE_N = 10;

// context
// -------

type SelectLabelContextValue = {
  parentRef: RefObject<HTMLElement>;
  overflowRef: RefObject<HTMLElement>;
};

const SelectLabelContext = createContext<SelectLabelContextValue | undefined>(
  undefined
);

export function SelectLabelProvider({
  value,
  children,
}: {
  value: SelectLabelContextValue;
  children?: ReactNode;
}) {
  return (
    <SelectLabelContext.Provider value={value}>
      {children}
    </SelectLabelContext.Provider>
  );
}

function useSelectLabelContext() {
  const value = useContext(SelectLabelContext);
  if (!value)
    throw new Error("SelectLabel can only be used in SelectTrigger and Button");
  return value;
}

// utils
// -----

function isOverflowing(element: HTMLElement, slot: HTMLElement) {
  // we can't use offsetWidth and scrollWidth because they are rounded to the nearest pixel and
  // we need subpixel precision, so we use getBoundingClientRect().width and a hacky cloning trick
  const clone = element.cloneNode() as HTMLElement; // clone the element
  clone.innerHTML = element.innerHTML; // copy its content over
  clone.style.position = "fixed"; // prevent overflow constrains (we need the full width)
  clone.style.opacity = "0"; // make it invisible
  slot.appendChild(clone); // append the clone to the slot to retain the same CSS context
  // measure
  const elementWidth = element.getBoundingClientRect().width;
  const cloneWidth = clone.getBoundingClientRect().width;
  // compare
  const result = elementWidth < cloneWidth;
  clone.remove(); // clean up the clone
  return result;
}

function createLabelText(items: string[], visibleN: number) {
  const rest = [...items];
  const visibleItems = rest.splice(0, visibleN || 1);
  const itemsString = visibleItems.join(", ");
  const restString = rest.length > 0 ? `, +${rest.length}` : "";
  return `${itemsString}${restString}`;
}

type LabelData = { text: string; label: string | ReactNode };

/* eslint-disable no-param-reassign */
function computeLabelData(
  items: string[],
  {
    textElement,
    overflowElement,
    textSlotElement,
    overflowSlotElement,
  }: {
    overflowElement: HTMLElement; // contains the overflow
    textElement: HTMLElement; // contains the actual label
    textSlotElement: HTMLElement; // for testing label lengths
    overflowSlotElement: HTMLElement; // for testing overflow
  }
): LabelData {
  // setup
  let visibleItems = Math.min(INITIAL_VISIBLE_N + 1, items.length + 1);
  let labelText = "";
  textElement.style.display = "none";

  // compute
  do {
    visibleItems -= 1;
    labelText = createLabelText(items, visibleItems);
    textSlotElement.textContent = labelText;
  } while (
    isOverflowing(overflowElement, overflowSlotElement) &&
    visibleItems > 1
  );

  // cleanup
  textSlotElement.textContent = "";
  textElement.style.display = "";

  // return final label
  let label: string | ReactNode = labelText;
  if (visibleItems === 1) {
    // special case: only one visible item
    const restN = items.length - 1;
    label = (
      <span className="flex">
        <span className="truncate">{items[0]}</span>
        {restN > 0 && <span>, +{items.length - 1}</span>}
      </span>
    );
  }
  return { text: labelText, label };
}
/* eslint-enable no-param-reassign */

// select label component
// ----------------------

type SelectLabelProps = { items: string[] };

export function SelectLabel({ items }: SelectLabelProps) {
  const { parentRef, overflowRef } = useSelectLabelContext();

  const [labelText, setLabelText, labelTextRef] = useStateRef(
    createLabelText(items, INITIAL_VISIBLE_N)
  );
  const [label, setLabel] = useState<string | ReactNode>(labelText);

  const textRef = useRef<HTMLElement>(null);
  const textSlotRef = useRef<HTMLElement>(null);
  const overflowSlotRef = useRef<HTMLElement>(null);

  const computeAndSetLabel = useCallback(
    (newItems: string[]) => {
      if (
        !textRef.current ||
        !overflowRef.current ||
        !textSlotRef.current ||
        !overflowSlotRef.current
      )
        return;
      const newLabel = computeLabelData(newItems, {
        textElement: textRef.current,
        overflowElement: overflowRef.current,
        textSlotElement: textSlotRef.current,
        overflowSlotElement: overflowSlotRef.current,
      });
      if (newLabel.text !== labelTextRef.current) {
        setLabelText(newLabel.text);
        setLabel(newLabel.label);
      }
    },
    [labelTextRef, overflowRef, setLabelText]
  );

  // react to resize
  useResizeDetector({
    skipOnMount: true,
    targetRef: parentRef,
    handleHeight: false,
    onResize: () => computeAndSetLabel(items),
    // see https://github.com/maslianok/react-resize-detector/issues/45#issuecomment-829928247
    refreshMode: "debounce",
    refreshRate: 0,
  });

  // react to items change
  useIsomorphicLayoutEffect(() => {
    if (!overflowRef.current) setTimeout(() => computeAndSetLabel(items), 0);
    else computeAndSetLabel(items);
  }, [computeAndSetLabel, items, overflowRef]);

  return (
    <>
      <span ref={textRef}>{label}</span>
      <span ref={textSlotRef} />
      <span ref={overflowSlotRef} />
    </>
  );
}

// TODO: a lot of this can probably be cleaned up with useEvent
// TODO: there seems to be a bit of weirdness going on in Firefox
// TODO: isOverflowing can probably be optimized by cloning the text slot element
// rather than the full overflow element
